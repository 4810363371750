import React, { Component } from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'

import { getFluidImage } from '../../utils/images'

export default class ImageHeaderSlider extends Component {
  next = () => {
    this.slider.slickNext()
  }

  prev = () => {
    this.slider.slickPrev()
  }

  render () {
    const settings = {
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      swipeToSlide: true,
      adaptiveHeight: true
    }

    const { images, aspectRatio = 2.5, noTransform } = this.props

    return (
      <div className='parent country--header image-header-slider'>
        <Slider ref={c => (this.slider = c)} {...settings} className='dupa'>
          {images.map(image => {
            const { id, cloudinary, transformation, title, alt } = image
            return (
              <div className='slider-image-wrapper' key={id}>
                <Img
                  key={id}
                  loading='eager'
                  fadeIn={false}
                  fluid={getFluidImage({
                    cloudinary,
                    ar: aspectRatio,
                    transformation: noTransform ? '' : transformation
                  })}
                  style={{ padding: 0 }}
                  alt={alt || title}
                />
                {title && <div className='slider-image-title'>{title}</div>}
              </div>
            )
          })}
        </Slider>

        {images.length > 1 && (
          <>
            <div className='child--left'>
              <i
                className='icon ta-arrow-2-left huge'
                onClick={this.prev}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className='child--right'>
              <i
                className='icon ta-arrow-2-right huge'
                onClick={this.next}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </>
        )}
      </div>
    )
  }
}
