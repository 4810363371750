import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import Usercentrics from '@usercentrics/cmp-browser-sdk'
import { USERCENTRICS_ID } from '../../utils/constants'

const MapOverlay = ({ className, onClick, style, t }) => {

  useEffect(() => {
    const UC = new Usercentrics(USERCENTRICS_ID)
    UC.init().then(() => {
      const consents = {}
      UC.getServicesBaseInfo().forEach((s) => consents[s.name] = { id: s.id, status: s.consent.status })
      if (consents['Google Maps'].status) {
        onClick()
      }
    })
  }, [])

  return (
    <div className={'google-map-fallback ' + className} style={style}>
      <span>{t('map.overlay.heading')}</span>
      <button onClick={onClick}>{t('map.overlay.buttonText')}</button>
    </div>
  )
}

export default withTranslation()(MapOverlay)
