import React from 'react'
import { Breadcrumb, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from '../../locales'

import { filtersActions } from '../Filters/state'
import { localizeLink } from '../../utils/helpers'

const Breadcrumbs = ({
  setFilters,
  service,
  country,
  countrySlug,
  countryRegion,
  countryRegionSlug,
  region,
  regionSlug,
  location,
  locationSlug,
  title,
  t,
  i18n
}) => {
  let url = `/country-regions/${countryRegionSlug}/destinations/${countrySlug}`
  let urlRegion = `/all-services/regions/${regionSlug}`
  let urlLocation = `/locations/${locationSlug}`

  url = localizeLink(url, i18n.language)
  urlRegion = localizeLink(urlRegion, i18n.language)
  urlLocation = localizeLink(urlLocation, i18n.language)

  function handleRedirect ({ service, country, region, location }) {
    setFilters({ countryRegion, service, country, region, location })
  }

  const divider = <Breadcrumb.Divider>&nbsp;|&nbsp;</Breadcrumb.Divider>

  return (
    <Breadcrumb size='small'>
      <Breadcrumb.Divider>
        <Icon className='ta-locationmark' />
      </Breadcrumb.Divider>
      <Link to='/' lang={i18n.language}>
        <Breadcrumb.Section>{t('breadcrumbs.home')}</Breadcrumb.Section>
      </Link>
      {divider}
      <Link to={url} lang={i18n.language}>
        <Breadcrumb.Section>{country}</Breadcrumb.Section>
      </Link>
      {divider}
      <Link to={url} lang={i18n.language}>
        <Breadcrumb.Section>
          <span onClick={() => handleRedirect({ service })}>
            {t(`breadcrumbs.${service}`)}
          </span>
        </Breadcrumb.Section>
      </Link>
      {divider}
      <Link to={url + urlRegion} lang={i18n.language}>
        <Breadcrumb.Section>
          <span onClick={() => handleRedirect({ service, region })}>
            {region}
          </span>
        </Breadcrumb.Section>
      </Link>
      {divider}
      <Link to={url + urlRegion + urlLocation} lang={i18n.language}>
        <Breadcrumb.Section>
          <span onClick={() => handleRedirect({ service, region, location })}>
            {location}
          </span>
        </Breadcrumb.Section>
      </Link>
      {divider}
      <Breadcrumb.Section active>{title}</Breadcrumb.Section>
    </Breadcrumb>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/

const mapDispatchTopProps = {
  setFilters: filtersActions.setFilters
}

export default withTranslation()(
  connect(null, mapDispatchTopProps)(Breadcrumbs)
)
