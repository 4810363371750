import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Lightbox from 'react-images'

import { getFluidImage } from '../../utils/images'
import { navigationActions } from '../Navigations/state'

const ImageLightbox = ({ t, id, images, lightbox, setLightbox }) => {
  const imagesSrc = images.map(({ cloudinary, title, alt }) => {
    let fluidImage = getFluidImage({ cloudinary, options: 'bo_2px_solid_white/c_pad' })

    return {
      src: fluidImage.src,
      srcSet: fluidImage.srcSet.split(','),
      caption: title,
      alt: alt || title,
      title: title
    }
  })

  const slideIndex = images.findIndex(({ id }) => id === lightbox.image)

  function handleNextSlide() {
    setLightbox({ image: images[slideIndex + 1].id, open: id })
  }

  function handlePrevSlide() {
    setLightbox({ image: images[slideIndex - 1].id, open: id })
  }

  return (
    <Lightbox
      backdropClosesModal
      images={imagesSrc}
      isOpen={lightbox.open === id}
      onClose={() => setLightbox({ open: false, image: '' })}
      onClickPrev={handlePrevSlide}
      onClickNext={handleNextSlide}
      currentImage={slideIndex}
      width={1920}
      imageCountSeparator={` ${t('general.of')} `}
    />
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ navigation }) => ({ lightbox: navigation.lightbox })

const mapDispatchTopProps = {
  setLightbox: navigationActions.setLightbox
}

export default connect(mapStateToProps, mapDispatchTopProps)(withTranslation()(ImageLightbox))
