import React from 'react'
import Slider from 'react-slick'
import { Header } from 'semantic-ui-react'
import Video from './Video'

const Videos = ({ videos, slider, showTitles }) => {
  const Arrow = ({ onClick, direction }) => (
    <i
      className={`video-${direction} ta-arrow-1-${direction} icon large`}
      style={{ display: 'block', cursor: 'pointer', color: 'black' }}
      onClick={onClick}
    />
  )

  const settings = {
    className: 'slider',
    infinite: true,
    slidesToShow: 1,
    lazyLoad: true,
    dots: true,
    prevArrow: <Arrow direction='left' />,
    nextArrow: <Arrow direction='right' />
  }

  const renderVideos = videos =>
    videos.map((video, idx) => (
      <div key={idx}>
        {showTitles && (
          <Header as='h4' color='red'>
            {video.name}
          </Header>
        )}
        <Video key={idx} videoId={video.videoID} videoName={video.name} />
      </div>
    ))

  return (
    <div className='videos'>
      {slider && videos.length > 1 ? (
        <Slider {...settings}>{renderVideos(videos)}</Slider>
      ) : (
        <div className='list'>{renderVideos(videos)}</div>
      )}
    </div>
  )
}

export default Videos
