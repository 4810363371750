import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { Segment } from "semantic-ui-react";
import MapOverlay from "./MapOverlay";

const MapGeo = ({ lat, lng, title, height = 250 }) => {
  const [load, setLoad] = useState(false);

  function renderMarkers(map, maps) {
    return new maps.Marker({
      position: { lat, lng },
      map,
      title,
    });
  }

  return (
    <Segment
      vertical
      basic
      style={{ width: "100%", height, position: "relative" }}
    >
      {(load && (
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: process.env.MAP_API_KEY }}
          defaultCenter={{ lat, lng }}
          defaultZoom={17}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        />
      )) || (
          <MapOverlay
            onClick={() => setLoad(true)}
            style={{ top: "16px", bottom: "16px" }}
          />
        )}
    </Segment>
  );
};

export default MapGeo;
