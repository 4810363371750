import React, { Component } from 'react'
import { Container, Grid, Segment, Header } from 'semantic-ui-react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'

import ImageLightbox from '../components/Items/ImageLightbox'
import ImageHeaderSlider from '../components/Items/ImageHeaderSlider'
import ImageSectionSlider from '../components/Items/ImageSectionSlider'
import Layout from '../components/Layouts/PageLayout'
import MapGeo from '../components/Map/MapGeo'
import Bubble from '../components/Common/Bubble'
import Share from '../components/Common/Share'
import Breadcrumb from '../components/Common/Breadcrumb'
import Videos from '../components/Common/Videos'
import PageHeader from '../components/Items/PageHeader'
import HotelDescription from '../components/Items/HotelDescription'
import PriceCard from '../components/Items/PriceCard'
import PriceForm from '../components/Items/PriceForm'
import AdditionalOffers from '../components/Items/AdditionalOffers'

import { getRawImage } from '../utils/images'

class HotelPage extends Component {
  render() {
    const { data, pageContext, t } = this.props
    const {
      collection,
      description,
      geo,
      price,
      title,
      intro,
      categories,
      stars,
      country,
      countrySlug,
      region,
      regionSlug,
      location,
      locationSlug,
      countryRegion,
      countryRegionSlug,
      images,
      teaser,
      slug,
      seo,
      relations,
      htlCode,
      destCode,
      showPrices,
      videos
    } = data.firestoreHotel

    const allImages = [
      ...(images.intro || []),
      ...(images.room || []),
      ...(images.food || []),
      ...(images.entertainment || []),
      ...(images.location || []),
      ...(images.opinion || [])
    ]

    return (
      <>
        <Helmet>
          <title>{seo.htmlTitle}</title>

          <meta name='description' content={seo.description} />
          <meta
            name='image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />

          <meta property='og:title' content={title} />
          <meta property='og:description' content={seo.description} />
          <meta
            property='og:image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='Tischler Reisen' />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:description' content={seo.description} />
          <meta
            name='twitter:image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />
        </Helmet>

        <Layout pageContext={pageContext}>
          <ImageHeaderSlider images={images.headers} />

          {(teaser.header || teaser.content) && (
            <Bubble title={teaser.header} text={teaser.content} inverted />
          )}

          <PageHeader
            categories={categories}
            stars={stars}
            title={seo.h1}
            intro={intro}
          />

          <Segment vertical padded='very' basic>
            <Container>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column computer={12}>
                    <Breadcrumb
                      service={collection}
                      country={country}
                      countrySlug={countrySlug}
                      countryRegion={countryRegion}
                      countryRegionSlug={countryRegionSlug}
                      region={region}
                      regionSlug={regionSlug}
                      location={location}
                      locationSlug={locationSlug}
                      title={title}
                    />
                  </Grid.Column>

                  <Grid.Column textAlign='right' computer={4}>
                    <Share color='grey' />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Column computer={11} tablet={11}>
                  {images.intro && (
                    <ImageSectionSlider
                      images={images.intro}
                      lightboxId={slug}
                    />
                  )}

                  {showPrices && (
                    <PriceForm hotelData={{ destCode, htlCode }} />
                  )}

                  <HotelDescription
                    lightboxId={slug}
                    section={description.facility}
                    images={images.room}
                  />
                  <HotelDescription
                    lightboxId={slug}
                    section={description.catering}
                    images={images.food}
                  />
                  <HotelDescription
                    lightboxId={slug}
                    section={description.leisure}
                    images={images.entertainment}
                  />
                  <HotelDescription
                    lightboxId={slug}
                    section={description.location}
                    images={images.location}
                  />
                  <HotelDescription
                    lightboxId={slug}
                    section={description.opinion}
                    images={images.opinion}
                  />

                  {images && <ImageLightbox id={slug} images={allImages} />}
                  {description.specials && description.specials.description && (
                    <div className='ui vertical very padded segment'>
                      <Header as='h2' color='red'>
                        Specials
                      </Header>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: description.specials.description
                        }}
                      />
                    </div>
                  )}
                </Grid.Column>

                <Grid.Column computer={5} tablet={5}>
                  <PriceCard
                    header={t('hotel-details.hotel-ab')}
                    content={t('hotel-details.person')}
                    price={price}
                    type={collection}
                    item={data.firestoreHotel}
                    centered
                    showPrice={false}
                  />

                  <MapGeo lat={geo.lat} lng={geo.long} title={title} />

                  {videos && videos.length > 0 && (
                    <Videos videos={videos} showTitles />
                  )}
                </Grid.Column>
              </Grid>

              <AdditionalOffers country={country} relations={relations} />
            </Container>
          </Segment>
        </Layout>
      </>
    )
  }
}

export default withTranslation()(HotelPage)

/**************************************************************
 * GRAPHQL
 **************************************************************/
export const pageQuery = graphql`
  query HotelQuery($slug: String!, $lang: String!) {
    firestoreHotel(slug: { eq: $slug }, lang: { eq: $lang }) {
      title
      lang
      stars
      intro
      categories
      collection
      location
      region
      country
      countryRegion
      countryRegionSlug
      price
      showPrices
      description {
        specials {
          description
        }
      }
      teaser {
        header
        content
      }
      videos {
        name
        type
        videoID
      }
      seo {
        htmlTitle
        description
        h1
      }
      images {
        headers {
          id
          cloudinary
          title
          transformation
          alt
        }
        room {
          id
          cloudinary
          title
          alt
        }
        location {
          id
          cloudinary
          title
          alt
        }
        intro {
          id
          cloudinary
          title
          alt
        }
        food {
          id
          cloudinary
          title
          alt
        }
        entertainment {
          id
          cloudinary
          title
          alt
        }
      }
      coverImage
      geo {
        lat
        long
      }
      slug
      countrySlug
      regionSlug
      locationSlug
      htlCode
      destCode
      description {
        catering {
          description
          title
        }
        facility {
          title
          description
        }
        leisure {
          description
          title
        }
        opinion {
          description
          title
        }
        location {
          description
          title
        }
      }
    }
  }
`
